import { AuthConfig } from 'angular-oauth2-oidc'
import { environment } from 'src/environments/environment'

export const OAuthConfig: AuthConfig = {
	issuer: environment.issuer,
	clientId: environment.clientId,
	strictDiscoveryDocumentValidation: false,
	redirectUri: window.location.origin + '/home',
	scope: 'openid profile',
	responseType: 'code',
	requireHttps: environment.requireHttps,
}
