/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core'
import { OAuthService as OAuthOIDCService, OAuthStorage } from 'angular-oauth2-oidc'
import { OAuthConfig } from '@core/config/oauth.config'
import { Router } from '@angular/router'
import { environment } from '@environments/environment'

/**
 * Servicio que contiene la lógica de autenticación con OAuth2
 */
@Injectable({
	providedIn: 'root',
})
export class OAuthService {
	constructor(
		private readonly oauthService: OAuthOIDCService,
		private readonly oauthStorage: OAuthStorage,
		private router: Router
	) {
		this.setupConfig()
	}

	// Configura el servicio de autenticación OAuth2
	private setupConfig(): void {
		this.oauthService.configure(OAuthConfig)
		this.oauthService.loadDiscoveryDocumentAndTryLogin()
		this.oauthService.setupAutomaticSilentRefresh()
	}

	// Inicia el proceso de autenticación
	singIn(): void {
		this.oauthService.initCodeFlow()
	}

	// Verifica si el usuario está autenticado
	isAuthenticated(): boolean {
		const accessToken = this.oauthStorage.getItem('PKCE_verifier')

		if (accessToken) {
			return true
		}

		return false
	}

	// Obtiene las reclamaciones de identidad del usuario
	getIdentityClaims(): Record<string, any> {
		return this.oauthService.getIdentityClaims()
	}

	// Refresca la sesión del usuario
	refreshSession(): Promise<boolean> {
		return this.oauthService
			.refreshToken()
			.then(() => true)
			.catch(() => false)
	}

	// Obtiene la fecha de expiración de la sesión del usuario
	getSessionExpiration(): number {
		const tokenExp = this.oauthService.getIdentityClaims()['exp'] as number

		if (tokenExp) {
			return tokenExp
		}

		return 0
	}

	// Cierra la sesión del usuario
	signOut(): void {
		const window = this.openLogoutWindow()

		setTimeout(() => {
			window?.close()
			this.oauthService.logOut(true)
			this.router.navigate(['auth/login'])
		}, 1000)
	}

	// Abre una ventana emergente para cerrar la sesión del usuario
	private openLogoutWindow(): Window | null {
		const width = 300
		const height = 300

		// Calcula el centro de la pantalla
		const left = (window.innerWidth - width) / 2
		const top = (window.innerHeight - height) / 2

		// Construye la URL de cierre de sesión
		const logoutUrl = `${environment.logoutUri}logout?response_type=code&client_id=${environment.clientId}&redirect_uri=${environment.redirectUri}&scope=openid+profile`

		// Abre la ventana emergente centrada
		return window.open(logoutUrl, 'LOGOUT', `width=${width},height=${height},left=${left},top=${top},scrollbars=NO`)
	}

	getIdToken(): string {
		return this.oauthService.getIdToken()
	}
}
